import { useGetStatistics, useGetStatisticsForAll } from "../services/ContentManager";
import { useGetPublicOrganizationSettings } from "services/ContentOpenManager";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "./App";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StatsCard from "../components/charts/StatsCard";
import NTMBubbleChart from "../components/charts/NTMBubbleChart";
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import StartIconButton from "../components/buttons/StartIconButton";
import ResponsiveCircularLoading from "../components/ResponsiveCircularLoading";
import DateIntervalModal from "../components/modals/DateIntervalModal";
import { stringToUnix } from "../services/helper";
import NTMBarChart from "../components/charts/NTMBarChart";
import NTMLinearBar from "../components/charts/NTMLinearBar";
import { LEG_TYPE, legTypesEndings, useGetLegTypeTranslation } from "../constants/legType";
import { Pill } from "../components/SettingsPills";
import { currency } from "constants/currency";
import {AGE_ORDER, AGE_RANGES} from "../constants/ageRanges";


export default function Statistics({ disableDateInterval, overrideInterval, startDateUrbanPoints, endDateUrbanPoints }) {
    const { t } = useTranslation();
    const user = useContext(UserContext);
    const { id } = useParams();
    let isAll = id == "all" ? true : false;
    const oid = user.organization || id;
    const legTypeTranslation = useGetLegTypeTranslation();
  
    //la vista delle statistiche prende le sessioni degli ultimi 180 giorni
    let minLimitDate = dayjs(new Date()).subtract(180, 'day').format("YYYY-MM-DD")
  
    //ottimizzazioni per non rifare la query
    const { settings = [], status: settingsStatus } = useGetPublicOrganizationSettings(oid, {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: dayjs.duration(1, 'day').asMilliseconds(),
    });
    const [startDate, setStartDate] = useState(startDateUrbanPoints ? startDateUrbanPoints : {});
    const [endDate, setEndDate] = useState(endDateUrbanPoints ? endDateUrbanPoints : {});
    useEffect(() => {
        if (startDate == {} && endDate == null && settingsStatus === 'success') {
            console.log(settings)
            console.log(overrideInterval)
            let newStartDate = settings.find(s => s.organizationSetting === 'startDateUrbanPoints') || {};
            if (dayjs(newStartDate.value).diff(dayjs(minLimitDate)) < 0) {
                newStartDate.value = minLimitDate;//la data di inizio non deve essere inferiore a quella della vista delle statistiche
            }
            setStartDate(newStartDate);

            let newEndDate = settings.find(s => s.organizationSetting === 'endDateUrbanPoints') || {};
            if (dayjs(newEndDate.value).diff(dayjs(new Date())) > 0) {
                newEndDate.value = dayjs(new Date()).format("YYYY-MM-DD")//la data di fine non deve essere nel futuro
            }
            setEndDate(newEndDate);
        }
    }, [settingsStatus]);

    const [intervalModal, setIntervalModal] = useState(false);
    const [enableLegsQuery, setEnableLegsQuery] = useState(false);
    let [{
        status: sessionsStatus,
        data: sessionsStatistics
    }, {
        status: sessionsTypeStatus,
        data: sessionsTypesStatistics
    }, {
        status: sessionsVolumeByHourStatus,
        data: sessionsVolumeByHour
    }, {
        status: sessionsVolumeByDayStatus,
        data: sessionsVolumeByDay
    }, {
        status: usersVolumeByAgeStatus,
        data: usersVolumeByAge
    }, {
        status: usersStatisticsStatus,
        data: usersStatistics
    }, {
        status: legStatisticsStatus,
        data: legStatistics
    }] = useGetStatistics(
      oid,
      stringToUnix(overrideInterval ? overrideInterval.start : startDate.value),
      overrideInterval?.end || endDate?.value
        ? dayjs
            .utc(
              overrideInterval ? overrideInterval.end : endDate.value,
              "YYYY-MM-DD"
            )
            .add(1, "day")
            .valueOf()
        : null,
      enableLegsQuery,
      isAll
    );
  
    let [
      { status: sessionsStatusAll, data: sessionsStatisticsAll },
      { status: sessionsTypeStatusAll, data: sessionsTypesStatisticsAll },
      { status: sessionsVolumeByHourStatusAll, data: sessionsVolumeByHourAll },
      { status: sessionsVolumeByDayStatusAll, data: sessionsVolumeByDayAll },
      { status: usersVolumeByAgeStatusAll, data: usersVolumeByAgeAll },
      { status: usersStatisticsStatusAll, data: usersStatisticsAll },
      { status: legStatisticsStatusAll, data: legStatisticsAll },
    ] = useGetStatisticsForAll(
      stringToUnix(overrideInterval ? overrideInterval.start : startDate.value),
      overrideInterval?.end || endDate?.value
        ? dayjs
            .utc(
              overrideInterval ? overrideInterval.end : endDate.value,
              "YYYY-MM-DD"
            )
            .add(1, "day")
            .valueOf()
        : null,
      enableLegsQuery,
      !isAll
    );
  
    if (isAll && sessionsStatusAll) {
      sessionsStatus = sessionsStatusAll;
      sessionsStatistics = sessionsStatisticsAll;
      sessionsTypeStatus = sessionsTypeStatusAll;
      sessionsTypesStatistics = sessionsTypesStatisticsAll;
      sessionsVolumeByHourStatus = sessionsVolumeByHourStatusAll;
      sessionsVolumeByHour = sessionsVolumeByHourAll;
      sessionsVolumeByDayStatus = sessionsVolumeByDayStatusAll;
      sessionsVolumeByDay = sessionsVolumeByDayAll;
      usersVolumeByAgeStatus = usersVolumeByAgeStatusAll;
      usersVolumeByAge = usersVolumeByAgeAll;
      usersStatisticsStatus = usersStatisticsStatusAll;
      usersStatistics = usersStatisticsAll;
      legStatisticsStatus = legStatisticsStatusAll;
      legStatistics = legStatisticsAll;
    }
  
    usersStatistics = usersStatistics ? usersStatistics[0] : {};
    sessionsStatistics = sessionsStatistics ?? {};
    let totUsers = usersStatistics.unknown + usersStatistics.male + usersStatistics.female;

    const totalEuroLabel = () => {
        if (sessionsStatistics && sessionsStatistics.currency) {
            let currencyLabel = currency.find(c => sessionsStatistics.currency == c.code).label;
            return t('totalEuro').replaceAll('Euro', currencyLabel);
        } else {
            return t('totalEuro');
        }
    }
  
    const cards = [
        {
            title: t('users'),
            value: totUsers
        },
        {
            title: t('sessions'),
            value: sessionsStatistics.sessions,
        }, {
            title: totalEuroLabel(),
            value: sessionsStatistics.totalEuros,
            unit: sessionsStatistics.currency,
            precision: 2
        }, {
            title: t('totalKm'),
            value: sessionsStatistics.totalKm,
            unit: 'Km',
            precision: 1
        }, {
            title: t('totalCo2'),
            value: sessionsStatistics.totalCo2,
            unit: 'Kg',
            precision: 1,
            multiplier: 1 / 1000
        }, {
            placeholder: true
        }, {
            title: t('avgKmSessions'),
            value: sessionsStatistics.avgKm,
            unit: 'Km',
            precision: 2
        }, {
            title: t('avgKmHomeWork'),
            value: sessionsStatistics.avgKmHomeWork,
            unit: 'Km',
            precision: 2
        }, {
            title: t('avgKm'),
            value: sessionsStatistics.avgKmNoHomeWork,
            unit: 'Km',
            precision: 2
        },
    ];

    let totalKmCards = useMemo(() => {
        let totalKmCards = [];
        //---- Km per leg -----
        Object.values(LEG_TYPE).filter(t => t !== LEG_TYPE.NONE).forEach((type) => {
            totalKmCards.push({
                title: `${legTypeTranslation[type]}`,
                value: legStatistics && legStatistics.find(l => l.legType === type)?.totalKm,
                unit: 'Km',
                precision: 1
            })
        });
        return totalKmCards;
    }, [legStatistics, legTypeTranslation]);

    let avgKmCards = useMemo(() => {
        let avgKmCards = [];
        //---- Avg Km per leg -----
        Object.values(LEG_TYPE).filter(t => t !== LEG_TYPE.NONE).forEach((type) => {
            avgKmCards.push({
                title: `${legTypeTranslation[type]}`,
                value: legStatistics && legStatistics.find(l => l.legType === type)?.avgKm,
                unit: 'Km',
                precision: 1
            })
        });
        return avgKmCards;
    }, [legStatistics, legTypeTranslation]);


    //wrap in use memo
    let co2Cards = useMemo(() => {
        let co2Cards = [];
        //---- Co2 per leg -----
        Object.values(LEG_TYPE).filter(t => t !== LEG_TYPE.NONE).forEach((type) => {
            co2Cards.push({
                title: `${legTypeTranslation[type]}`,
                value: legStatistics && legStatistics.find(l => l.legType === type)?.totalCo2,
                unit: 'Kg',
                precision: 1,
                multiplier: 1 / 1000
            })
        });
        return co2Cards;
    }, [legStatistics, legTypeTranslation]);

    let LegsCards = [
        {
            title: t('totalKm'),
            cards: totalKmCards
        }, {
            title: t('avgKmLeg'),
            cards: avgKmCards
        }, {
            title: t('totalCo2'),
            cards: co2Cards
        }
    ];

    const setDates = (interval) => {
        setStartDate({ ...startDate, value: interval.start });
        setEndDate({ ...endDate, value: interval.end });
    }

    function getRange(obj) {
        return obj.split('-')[0].replaceAll('<', '').replaceAll('>', '').trim();
    }

    //format di interval per semplificare
    let interval = {
        start: dayjs(new Date()).valueOf(),
        end: dayjs(new Date()).valueOf()
    }
    interval.start = startDate ? startDate.value : interval.start;
    interval.end = endDate ? endDate.value : interval.end;

    return (
        <Grid container direction={'column'} sx={(t) => ({ maxWidth: t.breakpoints.values.md, margin: 'auto' })}
            rowGap={3}>
            {!disableDateInterval && <Grid item>
                <StartIconButton
                    onClick={() => setIntervalModal(true)}
                    title={
                        settingsStatus === 'loading' ?
                            <ResponsiveCircularLoading width={'1.5em'} />
                            :
                            <Typography variant="subtitle1" component="div"
                                style={{ fontWeight: 600, paddingLeft: "2px", paddingTop: "1px" }}>
                                {dayjs(interval.start).format("DD/MM/YYYY") + " - " + dayjs(interval.end).format("DD/MM/YYYY")}
                            </Typography>
                    }
                    startIcon={<DateRangeIcon />}
                />
                <DateIntervalModal
                    interval={interval}
                    onClose={() => setIntervalModal(false)}
                    open={intervalModal}
                    onSubmit={setDates}
                />
            </Grid>}
            <Grid item>
                <Grid container alignItems={"stretch"} justifyContent={"space-between"}>
                    {
                        cards.map((c, k) =>
                            <Grid item lg={4} sm={6} xs={12} key={k} sx={{ p: 2 }} {...c.placeholderProps}>
                                {
                                    !c.placeholder &&
                                    <StatsCard title={c.title} value={c.value} unit={c.unit} precision={c.precision}
                                        loading={sessionsStatus === 'loading'}
                                        multiplier={c.multiplier}
                                        info={c.info} />
                                }
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            <Grid item>
                <Grid container gap={2}>
                    {
                        LegsCards.map(({ title, cards }) => (
                            <Grid item xs={12} key={title}>
                                <Pill lg={12} open={true} expandIconColor="secondary" 
                                onExpandedChange={(expanded) => setEnableLegsQuery(expanded)}
                                pill={{
                                    id: 0,
                                    title: title,
                                    component: <Grid container alignItems={"stretch"} justifyContent={"space-between"}>
                                        {
                                            cards.map((c, k) =>
                                                <Grid item lg={4} sm={6} xs={12} key={k} sx={{ p: 2 }} {...c.placeholderProps}>
                                                    {
                                                        !c.placeholder &&
                                                        <StatsCard title={c.title} value={c.value} unit={c.unit} precision={c.precision}
                                                            loading={legStatisticsStatus === 'loading'}
                                                            multiplier={c.multiplier}
                                                            info={c.info} />
                                                    }
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                }} index={0} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
            <Grid item sx={{ p: 2, px: 4 }}>
                <NTMLinearBar
                    data={sessionsStatistics &&
                    {
                        leisure: sessionsStatistics.leisure,
                        commute: sessionsStatistics.sessions - sessionsStatistics.leisure,
                    }
                    }
                    loading={sessionsStatus === 'loading'}
                    labels={[t('leisure'), t('commute')]}
                />
            </Grid>
            <Grid item sx={{ p: 2, px: 4 }}>
                <NTMLinearBar
                    data={sessionsTypesStatistics &&
                    {
                        bike: ((sessionsTypesStatistics.find(s => s.type === 'BIKE') || {}).sessions ?? 0),
                        commute: ((sessionsTypesStatistics.find(s => s.type === 'ELECTRIC_BIKE') || {}).sessions ?? 0),
                    }
                    }
                    loading={sessionsTypeStatus === 'loading'}
                    labels={[t('muscleBike'), t('eBike')]}
                />
            </Grid>
            <Grid item sx={{ p: 2, px: 4 }}>
                <NTMBubbleChart
                    data={sessionsVolumeByHour && ([...Array(24).keys()]).map(
                        h => ({
                            field: h,
                            value: (sessionsVolumeByHour.find(s => s.hour === h) || {}).sessions
                        }))}
                    loading={sessionsVolumeByHourStatus === 'loading'}
                    label={t('volumesByHour')}
                />
            </Grid>
            <Grid item sx={{ p: 2, px: 4 }}>
                <NTMBubbleChart
                    data={sessionsVolumeByDay && dayjs.weekdays().map((day, k) => ({ // per giorni del mese [...Array(dayjs().daysInMonth()).keys()]
                        field: day,
                        value: (sessionsVolumeByDay.find(s => s.day === k) || {}).sessions
                    }))}
                    loading={sessionsVolumeByDayStatus === 'loading'}
                    label={t('volumesByDay')}
                />
            </Grid>
            <Grid item sx={{ my: 1 }}>
                <Typography align={'center'} variant={'h5'}>{t('usersStatistics')}</Typography>
            </Grid>
            <Grid item sx={{ p: 2, px: 4 }}>
                <NTMLinearBar
                    data={usersStatistics &&
                    {
                        male: usersStatistics.male,
                        female: usersStatistics.female,
                        unknown: usersStatistics.unknown
                    }
                    }
                    loading={usersStatisticsStatus === 'loading'}
                    labels={[t('male'), t('female'), t('notDefined')]}
                />
            </Grid>
            <Grid item sx={{ p: 2, px: 4 }}>
                <NTMLinearBar
                    data={sessionsStatistics &&
                    {
                        hasCar: usersStatistics.hasCar,
                        withoutCar: totUsers - usersStatistics.hasCar,
                    }
                    }
                    loading={sessionsStatus === 'loading'}
                    labels={[t('usersWithCar'), t('usersWithoutCar')]}
                />
            </Grid>
            <Grid item sx={{ p: 2, px: 4 }}>
                <NTMBarChart
                    data={usersVolumeByAge && usersVolumeByAge.sort((a, b) => {
                        // Ordina in base alla posizione nel array AGE_ORDER
                        //se il risultato è negativo A viene prima di B e viceversa
                        return AGE_ORDER.indexOf(a.ageRange) - AGE_ORDER.indexOf(b.ageRange);
                    })
                        .map(u => ({
                            //si assicura che venga decodificata la stringa in HTML
                            field: new DOMParser().parseFromString(AGE_RANGES[u.ageRange], 'text/html').documentElement.textContent,
                            value: u.users
                        }))}
                    loading={usersVolumeByAgeStatus === 'loading'}
                    label={t('usersByAgeRange')}
                />
            </Grid>
        </Grid>
    )
}
